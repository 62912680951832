import type { ZudokuConfig } from "zudoku";

const config: ZudokuConfig = {
  topNavigation: [
    { id: "intro", label: "Introduction" },
    { id: "auth", label: "Authentication" },
    { id: "api", label: "API Reference" },
  ],
  redirects: [{ from: "/", to: "/docs/introduction" }],
  sidebar: {
    "intro": [
      {
        "type": "category",
        "label": "Introduction",
        "items": ['docs/introduction']
      },
      {
        "type": "link",
        "label": "Authentication",
        "href": "docs/authentication"
      },
      {
        "type": "link",
        "label": "API Reference",
        "href": "/api",
      }
    ],
    "auth": [
      {
        "type": "category",
        "label": "Authentication",
        "items": ['docs/authentication']
      }
    ]
  },
  apis: {
    type: "file",
    input: "../OpenApi/openapi.json",
    navigationId: "api",
  },
  page: {
    logoUrl: "logo-portal.png",
  },
  metadata: {
    title: 'eBill Service developer API',
    favicon: "favicon.ico"
  }
};

export default config;
